import React, { FC, ReactNode } from 'react'
import { PageMetadata } from 'src/components/page-metadata'

interface LayoutProps {
  children: ReactNode
}
export const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <PageMetadata />
      {children}
    </>
  )
}
