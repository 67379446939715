import { gql } from '@apollo/client'
import * as ApolloReactCommon from '@apollo/client'
import * as ApolloReactHooks from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any
}

export type Benefit = {
  __typename?: 'Benefit'
  description: Scalars['String']
  type: Scalars['String']
}

export type CalculateInput = {
  additionalInfo?: InputMaybe<Array<CustomAnsInput>>
  age?: InputMaybe<Scalars['Float']>
  conditions?: InputMaybe<Array<MedicalConditionInput>>
  faceValue?: InputMaybe<Scalars['Float']>
  gender?: InputMaybe<Gender>
  height?: InputMaybe<Scalars['Float']>
  lifestyle?: InputMaybe<Array<LifestyleInput>>
  medications?: InputMaybe<Array<MedicineInput>>
  occupation?: InputMaybe<Scalars['String']>
  postalCode?: InputMaybe<Scalars['String']>
  smoker?: InputMaybe<Smoker>
  term?: InputMaybe<Scalars['Float']>
  weight?: InputMaybe<Scalars['Float']>
}

export type CalculateOutput = {
  __typename?: 'CalculateOutput'
  policy: PolicyInfo
  products: Array<Product>
  provider: ProviderInfo
}

export type CreateFormDesignInput = {
  config: DesignConfigInput
  name?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  type?: InputMaybe<DesignType>
}

export type CreateOrUpdateUserInput = {
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
}

export type CustomAnsInput = {
  label?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  type: Scalars['String']
  value: Scalars['String']
}

export type CustomInfo = {
  __typename?: 'CustomInfo'
  defaultValue?: Maybe<Scalars['String']>
  label: Scalars['String']
  name: Scalars['String']
  options?: Maybe<Array<CustomOption>>
  otherOptions?: Maybe<Array<OtherOption>>
  required: Scalars['Boolean']
  title?: Maybe<Scalars['String']>
  type: Scalars['String']
}

export type CustomOption = {
  __typename?: 'CustomOption'
  defaultValue?: Maybe<Scalars['String']>
  label: Scalars['String']
  type: Scalars['String']
  value: Scalars['String']
}

export type DesignConfig = {
  __typename?: 'DesignConfig'
  elements: Array<Scalars['JSONObject']>
  thumbnail?: Maybe<Scalars['String']>
}

export type DesignConfigInput = {
  elements: Array<Scalars['JSONObject']>
  thumbnail?: InputMaybe<Scalars['String']>
}

/** The type of this design. e.g. form, template */
export enum DesignType {
  Form = 'Form',
  Template = 'Template',
}

export type EmailLoginInput = {
  email: Scalars['String']
  token: Scalars['String']
}

export type EmailLoginOutput = {
  __typename?: 'EmailLoginOutput'
  accessToken: Scalars['String']
  email: Scalars['String']
  id: Scalars['String']
  name: Scalars['String']
}

export type FieldAnswer = {
  name: Scalars['String']
  type: FieldAnswerType
  value: Scalars['String']
}

export enum FieldAnswerType {
  Bool = 'BOOL',
  Text = 'TEXT',
}

export type FinishApplicationInput = {
  appId: Scalars['String']
  email: Scalars['String']
}

export type FormBuilderDataUpdateInput = {
  config?: InputMaybe<DesignConfigInput>
  id: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
}

export type FormBuilderLoginInput = {
  email: Scalars['String']
  password: Scalars['String']
}

export type FormBuilderLoginOutput = {
  __typename?: 'FormBuilderLoginOutput'
  accessToken: Scalars['String']
  email: Scalars['String']
}

export type FormDesignOutput = {
  __typename?: 'FormDesignOutput'
  config: DesignConfig
  createdAt: Scalars['Float']
  id: Scalars['String']
  name: Scalars['String']
  owner: Scalars['String']
  title?: Maybe<Scalars['String']>
  type: DesignType
  updatedAt: Scalars['Float']
}

export type FormInput = {
  additionalInfo?: InputMaybe<Array<CustomAnsInput>>
  address?: InputMaybe<Scalars['String']>
  annualIncome?: InputMaybe<Scalars['String']>
  appId?: InputMaybe<Scalars['String']>
  beneficiaryDob?: InputMaybe<Scalars['String']>
  beneficiaryName?: InputMaybe<Scalars['String']>
  beneficiaryRelation?: InputMaybe<Scalars['String']>
  birthPlace?: InputMaybe<Scalars['String']>
  conditions?: InputMaybe<Array<MedicalConditionInput>>
  dob?: InputMaybe<Scalars['String']>
  drivingLicense?: InputMaybe<Scalars['String']>
  drivingLicenseState?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  employer?: InputMaybe<Scalars['String']>
  faceValue?: InputMaybe<Scalars['Float']>
  firstName?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<Gender>
  height?: InputMaybe<Scalars['Float']>
  lastName?: InputMaybe<Scalars['String']>
  lifestyle?: InputMaybe<Array<LifestyleInput>>
  medications?: InputMaybe<Array<MedicineInput>>
  occupation?: InputMaybe<Scalars['String']>
  paymentAccountNumber?: InputMaybe<Scalars['String']>
  paymentAccountType?: InputMaybe<Scalars['String']>
  paymentInstitutionName?: InputMaybe<Scalars['String']>
  paymentRoutingNumber?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  postalCode?: InputMaybe<Scalars['String']>
  smoker?: InputMaybe<Smoker>
  ssn?: InputMaybe<Scalars['String']>
  term?: InputMaybe<Scalars['Float']>
  weight?: InputMaybe<Scalars['Float']>
}

export enum Gender {
  F = 'F',
  M = 'M',
}

export type GetFormDesignsInput = {
  filter?: InputMaybe<GetFormDesignsInputFilter>
}

export type GetFormDesignsInputFilter = {
  type?: InputMaybe<DesignType>
}

export type GetHumanApiDataOutput = {
  __typename?: 'GetHumanApiDataOutput'
  conditions?: Maybe<Array<MedicalCondition>>
  medications?: Maybe<Array<Medicine>>
}

export type GetRxMibResultInput = {
  appNumber: Scalars['Float']
  transactionId: Scalars['Float']
}

export type GetRxMibResultOutput = {
  __typename?: 'GetRxMibResultOutput'
  status: RxMibStatus
}

export type GetSignPdfInput = {
  answers?: InputMaybe<Array<FieldAnswer>>
  email?: InputMaybe<Scalars['String']>
  postalCode?: InputMaybe<Scalars['String']>
}

export type GetSignPdfOutput = {
  __typename?: 'GetSignPDFOutput'
  appId: Scalars['String']
  signURL: Scalars['String']
  status?: Maybe<Scalars['String']>
}

export type GetSignUrlOutput = {
  __typename?: 'GetSignURLOutput'
  additionalSignURLs?: Maybe<Array<Scalars['String']>>
  appId: Scalars['String']
  signURL: Scalars['String']
  status?: Maybe<Scalars['String']>
}

export enum InsuranceType {
  Rider = 'rider',
  Term = 'term',
  Whole = 'whole',
}

export type Lifestyle = {
  __typename?: 'Lifestyle'
  name: Scalars['String']
  yearsAgo?: Maybe<Scalars['Float']>
}

export type LifestyleInput = {
  name: Scalars['String']
  yearsAgo?: InputMaybe<Scalars['Float']>
}

export type MedicalCondition = {
  __typename?: 'MedicalCondition'
  name: Scalars['String']
  yearsAgo?: Maybe<Scalars['Float']>
}

export type MedicalConditionInput = {
  name: Scalars['String']
  yearsAgo?: InputMaybe<Scalars['Float']>
}

export type Medicine = {
  __typename?: 'Medicine'
  name: Scalars['String']
  yearsAgo?: Maybe<Scalars['Float']>
}

export type MedicineInput = {
  name: Scalars['String']
  yearsAgo?: InputMaybe<Scalars['Float']>
}

export type Mutation = {
  __typename?: 'Mutation'
  confirmEmailLogin: EmailLoginOutput
  createFormDesign?: Maybe<FormDesignOutput>
  createOrUpdateUser: Scalars['String']
  finishApplication: Scalars['String']
  finishReview: Scalars['String']
  formBuilderLogin?: Maybe<FormBuilderLoginOutput>
  postRxMibRequest: PostRxMibRequestOutput
  sendEmailLogin: SendEmailLoginOutput
  sendWelcomeEmail: Scalars['String']
  startReview: StartReviewOutput
  updateFormDesign?: Maybe<FormDesignOutput>
}

export type MutationConfirmEmailLoginArgs = {
  data: EmailLoginInput
}

export type MutationCreateFormDesignArgs = {
  data: CreateFormDesignInput
}

export type MutationCreateOrUpdateUserArgs = {
  data: CreateOrUpdateUserInput
}

export type MutationFinishApplicationArgs = {
  data: FinishApplicationInput
}

export type MutationFormBuilderLoginArgs = {
  data: FormBuilderLoginInput
}

export type MutationPostRxMibRequestArgs = {
  data: PostRxMibRequestInput
}

export type MutationSendEmailLoginArgs = {
  email: Scalars['String']
}

export type MutationSendWelcomeEmailArgs = {
  data: SendWelcomeEmailInput
}

export type MutationUpdateFormDesignArgs = {
  data: FormBuilderDataUpdateInput
}

export type OtherOption = {
  __typename?: 'OtherOption'
  defaultValue?: Maybe<Scalars['String']>
  label: Scalars['String']
  name: Scalars['String']
  placeholder?: Maybe<Scalars['String']>
  triggerValues: Array<Scalars['String']>
  type: Scalars['String']
}

export type PolicyInfo = {
  __typename?: 'PolicyInfo'
  additionalInfo?: Maybe<Array<CustomInfo>>
  benefits?: Maybe<Array<Benefit>>
  description?: Maybe<Array<Scalars['String']>>
  name: Scalars['String']
  nickname: Scalars['String']
  type: InsuranceType
}

export type PostRxMibRequestInput = {
  /** Date in yyyy/mm/dd format. */
  birthDate: Scalars['String']
  ssn: Scalars['String']
}

export type PostRxMibRequestOutput = {
  __typename?: 'PostRxMibRequestOutput'
  appNumber?: Maybe<Scalars['Float']>
  transactionId?: Maybe<Scalars['Float']>
}

export type Premium = {
  __typename?: 'Premium'
  monthly?: Maybe<Scalars['Float']>
  yearly?: Maybe<Scalars['Float']>
}

export type PremiumByTerm = {
  __typename?: 'PremiumByTerm'
  errors?: Maybe<Array<ProductError>>
  premium: Premium
  term: Scalars['Float']
}

export type Product = {
  __typename?: 'Product'
  additionalInfo?: Maybe<Array<CustomInfo>>
  applicableFaceValue?: Maybe<Scalars['Float']>
  benefits?: Maybe<Array<Benefit>>
  description?: Maybe<Array<Scalars['String']>>
  errors?: Maybe<Array<ProductError>>
  logo?: Maybe<Scalars['String']>
  name: Scalars['String']
  nickname: Scalars['String']
  notEnoughData?: Maybe<Scalars['Boolean']>
  premium: Premium
  premiumByTerm?: Maybe<Array<PremiumByTerm>>
  riders?: Maybe<Array<RiderProduct>>
  term?: Maybe<Scalars['Float']>
  type: InsuranceType
}

export type ProductError = {
  __typename?: 'ProductError'
  code?: Maybe<Scalars['String']>
  condition?: Maybe<MedicalCondition>
  lifestyle?: Maybe<Lifestyle>
  medication?: Maybe<Medicine>
  message: Scalars['String']
  occupation?: Maybe<Scalars['String']>
  type: Scalars['String']
}

export type ProviderInfo = {
  __typename?: 'ProviderInfo'
  name: Scalars['String']
  nickname: Scalars['String']
}

export type Query = {
  __typename?: 'Query'
  autoSuggest: Array<Scalars['String']>
  calculate: Array<CalculateOutput>
  getApplicationStatus: Scalars['String']
  getFormDesign?: Maybe<FormDesignOutput>
  getFormDesigns?: Maybe<Array<FormDesignOutput>>
  getHumanApiData: GetHumanApiDataOutput
  getHumanApiToken: Scalars['String']
  getQuote: CalculateOutput
  getRxMibResult: GetRxMibResultOutput
  getSignPDF: GetSignPdfOutput
  getSignURL: GetSignUrlOutput
  verifyEmailLogin: EmailLoginOutput
}

export type QueryAutoSuggestArgs = {
  term: Scalars['String']
  type: Scalars['String']
}

export type QueryCalculateArgs = {
  data: CalculateInput
  providers: Array<Scalars['String']>
}

export type QueryGetApplicationStatusArgs = {
  appId: Scalars['String']
  email: Scalars['String']
}

export type QueryGetFormDesignArgs = {
  id: Scalars['String']
}

export type QueryGetFormDesignsArgs = {
  data: GetFormDesignsInput
}

export type QueryGetQuoteArgs = {
  data: CalculateInput
  productName: Scalars['String']
  providerName: Scalars['String']
}

export type QueryGetRxMibResultArgs = {
  data: GetRxMibResultInput
}

export type QueryGetSignPdfArgs = {
  data: GetSignPdfInput
  policy: Scalars['String']
  provider: Scalars['String']
}

export type QueryGetSignUrlArgs = {
  data: FormInput
  policy: Scalars['String']
  provider: Scalars['String']
}

export type QueryVerifyEmailLoginArgs = {
  data: EmailLoginInput
}

export type RiderProduct = {
  __typename?: 'RiderProduct'
  additionalInfo?: Maybe<Array<CustomInfo>>
  description?: Maybe<Array<Scalars['String']>>
  name: Scalars['String']
  nickname: Scalars['String']
  premium: Premium
  type: InsuranceType
}

export enum RxMibStatus {
  Approved = 'approved',
  Referred = 'referred',
  Rejected = 'rejected',
}

export type SendEmailLoginOutput = {
  __typename?: 'SendEmailLoginOutput'
  email: Scalars['String']
  loginHint: Scalars['String']
  token: Scalars['String']
}

export type SendWelcomeEmailInput = {
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  phoneNumber: Scalars['String']
}

export enum Smoker {
  Ns = 'NS',
  S = 'S',
}

export type StartReviewOutput = {
  __typename?: 'StartReviewOutput'
  additionalSignURLs?: Maybe<Array<Scalars['String']>>
  signURL: Scalars['String']
  status?: Maybe<Scalars['String']>
}

export type CreateFormDesignMutationVariables = Exact<{
  data: CreateFormDesignInput
}>

export type CreateFormDesignMutation = {
  __typename?: 'Mutation'
  createFormDesign?: {
    __typename?: 'FormDesignOutput'
    id: string
    name: string
    title?: string | null
    type: DesignType
    config: { __typename?: 'DesignConfig'; elements: Array<any> }
  } | null
}

export type FormBuilderLoginMutationVariables = Exact<{
  data: FormBuilderLoginInput
}>

export type FormBuilderLoginMutation = {
  __typename?: 'Mutation'
  formBuilderLogin?: { __typename?: 'FormBuilderLoginOutput'; accessToken: string; email: string } | null
}

export type GetFormDesignQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetFormDesignQuery = {
  __typename?: 'Query'
  getFormDesign?: {
    __typename?: 'FormDesignOutput'
    id: string
    type: DesignType
    name: string
    title?: string | null
    updatedAt: number
    createdAt: number
    config: { __typename?: 'DesignConfig'; elements: Array<any> }
  } | null
}

export type GetFormDesignsQueryVariables = Exact<{
  data: GetFormDesignsInput
}>

export type GetFormDesignsQuery = {
  __typename?: 'Query'
  getFormDesigns?: Array<{
    __typename?: 'FormDesignOutput'
    id: string
    type: DesignType
    name: string
    title?: string | null
    owner: string
    updatedAt: number
    createdAt: number
    config: { __typename?: 'DesignConfig'; elements: Array<any>; thumbnail?: string | null }
  }> | null
}

export type UpdateFormDesignMutationVariables = Exact<{
  data: FormBuilderDataUpdateInput
}>

export type UpdateFormDesignMutation = {
  __typename?: 'Mutation'
  updateFormDesign?: {
    __typename?: 'FormDesignOutput'
    id: string
    name: string
    title?: string | null
    type: DesignType
    config: { __typename?: 'DesignConfig'; elements: Array<any> }
  } | null
}

export const CreateFormDesignDocument = gql`
  mutation createFormDesign($data: CreateFormDesignInput!) {
    createFormDesign(data: $data) {
      id
      name
      title
      type
      config {
        elements
      }
    }
  }
`
export type CreateFormDesignMutationFn = ApolloReactCommon.MutationFunction<
  CreateFormDesignMutation,
  CreateFormDesignMutationVariables
>

/**
 * __useCreateFormDesignMutation__
 *
 * To run a mutation, you first call `useCreateFormDesignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormDesignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormDesignMutation, { data, loading, error }] = useCreateFormDesignMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFormDesignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFormDesignMutation, CreateFormDesignMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<CreateFormDesignMutation, CreateFormDesignMutationVariables>(
    CreateFormDesignDocument,
    options,
  )
}
export type CreateFormDesignMutationHookResult = ReturnType<typeof useCreateFormDesignMutation>
export type CreateFormDesignMutationResult = ApolloReactCommon.MutationResult<CreateFormDesignMutation>
export type CreateFormDesignMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateFormDesignMutation,
  CreateFormDesignMutationVariables
>
export const FormBuilderLoginDocument = gql`
  mutation formBuilderLogin($data: FormBuilderLoginInput!) {
    formBuilderLogin(data: $data) {
      accessToken
      email
    }
  }
`
export type FormBuilderLoginMutationFn = ApolloReactCommon.MutationFunction<
  FormBuilderLoginMutation,
  FormBuilderLoginMutationVariables
>

/**
 * __useFormBuilderLoginMutation__
 *
 * To run a mutation, you first call `useFormBuilderLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormBuilderLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formBuilderLoginMutation, { data, loading, error }] = useFormBuilderLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFormBuilderLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<FormBuilderLoginMutation, FormBuilderLoginMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<FormBuilderLoginMutation, FormBuilderLoginMutationVariables>(
    FormBuilderLoginDocument,
    options,
  )
}
export type FormBuilderLoginMutationHookResult = ReturnType<typeof useFormBuilderLoginMutation>
export type FormBuilderLoginMutationResult = ApolloReactCommon.MutationResult<FormBuilderLoginMutation>
export type FormBuilderLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
  FormBuilderLoginMutation,
  FormBuilderLoginMutationVariables
>
export const GetFormDesignDocument = gql`
  query getFormDesign($id: String!) {
    getFormDesign(id: $id) {
      id
      type
      config {
        elements
      }
      name
      title
      updatedAt
      createdAt
    }
  }
`

/**
 * __useGetFormDesignQuery__
 *
 * To run a query within a React component, call `useGetFormDesignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormDesignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormDesignQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFormDesignQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetFormDesignQuery, GetFormDesignQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetFormDesignQuery, GetFormDesignQueryVariables>(GetFormDesignDocument, options)
}
export function useGetFormDesignLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFormDesignQuery, GetFormDesignQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetFormDesignQuery, GetFormDesignQueryVariables>(GetFormDesignDocument, options)
}
export type GetFormDesignQueryHookResult = ReturnType<typeof useGetFormDesignQuery>
export type GetFormDesignLazyQueryHookResult = ReturnType<typeof useGetFormDesignLazyQuery>
export type GetFormDesignQueryResult = ApolloReactCommon.QueryResult<GetFormDesignQuery, GetFormDesignQueryVariables>
export const GetFormDesignsDocument = gql`
  query getFormDesigns($data: GetFormDesignsInput!) {
    getFormDesigns(data: $data) {
      id
      type
      config {
        elements
        thumbnail
      }
      name
      title
      owner
      updatedAt
      createdAt
    }
  }
`

/**
 * __useGetFormDesignsQuery__
 *
 * To run a query within a React component, call `useGetFormDesignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormDesignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormDesignsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetFormDesignsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetFormDesignsQuery, GetFormDesignsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetFormDesignsQuery, GetFormDesignsQueryVariables>(GetFormDesignsDocument, options)
}
export function useGetFormDesignsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFormDesignsQuery, GetFormDesignsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetFormDesignsQuery, GetFormDesignsQueryVariables>(
    GetFormDesignsDocument,
    options,
  )
}
export type GetFormDesignsQueryHookResult = ReturnType<typeof useGetFormDesignsQuery>
export type GetFormDesignsLazyQueryHookResult = ReturnType<typeof useGetFormDesignsLazyQuery>
export type GetFormDesignsQueryResult = ApolloReactCommon.QueryResult<GetFormDesignsQuery, GetFormDesignsQueryVariables>
export const UpdateFormDesignDocument = gql`
  mutation updateFormDesign($data: FormBuilderDataUpdateInput!) {
    updateFormDesign(data: $data) {
      id
      name
      title
      type
      config {
        elements
      }
    }
  }
`
export type UpdateFormDesignMutationFn = ApolloReactCommon.MutationFunction<
  UpdateFormDesignMutation,
  UpdateFormDesignMutationVariables
>

/**
 * __useUpdateFormDesignMutation__
 *
 * To run a mutation, you first call `useUpdateFormDesignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormDesignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormDesignMutation, { data, loading, error }] = useUpdateFormDesignMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateFormDesignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFormDesignMutation, UpdateFormDesignMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<UpdateFormDesignMutation, UpdateFormDesignMutationVariables>(
    UpdateFormDesignDocument,
    options,
  )
}
export type UpdateFormDesignMutationHookResult = ReturnType<typeof useUpdateFormDesignMutation>
export type UpdateFormDesignMutationResult = ApolloReactCommon.MutationResult<UpdateFormDesignMutation>
export type UpdateFormDesignMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateFormDesignMutation,
  UpdateFormDesignMutationVariables
>
