import React, { FC } from 'react'
import { Helmet } from 'react-helmet'

export const PageMetadata: FC = () => {
  return (
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>Inszu form builder</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#fff" />
      <link rel="apple-touch-icon" href="/logo192.png" />
    </Helmet>
  )
}
